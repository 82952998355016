import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../Categories/addCategory/index.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import GiftClasses from '../gifts/Gifts.module.css';
import { Success, Error } from 'Util/Toast';
window.Buffer = window.Buffer || require('buffer').Buffer;

const CreateBulkNotifications = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (body) => {
        setLoading(true);
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addBulkNotifications("POST", JSON.stringify(body), token);
            if (response.Status === 1) {
                setLoading(false);
                navigate('/bulknotifications');
                Success(response.Message);
            } else {
                setLoading(false);
                Error(response.Message);
            }
        } catch (error) {
            console.error('Error adding Bulk Notifications:', error);
            setLoading(false);
            Error('Error adding Bulk Notifications');
        }
    };

    return (
        <Layout Active={'BulkNotifications'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Bulk Notifications</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 className={TutorClasses['main-header']}>Create Bulk Notifications</h2>
                    <form style={{ display: 'flex', flexDirection: 'column' }} className={TutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        {/* Title Field */}
                        <div style={{ width: '100%' }} className={TutorClasses['form-control']}>
                            <label htmlFor="Title">Title</label>
                            <input
                                type='text'
                                {...register('Title', { required: 'Please enter a title.' })}
                            />
                            {errors.Title && <p className={GiftClasses["error-message"]}>{errors.Title.message}</p>}
                        </div>

                        {/* Message Field */}
                        <div style={{ width: '100%' }} className={TutorClasses['form-control']}>
                            <label htmlFor="Message">Message</label>
                            <textarea
                                {...register('Message', { required: 'Please enter a message.' })}
                                rows="4"
                            />
                            {errors.Message && <p className={GiftClasses["error-message"]}>{errors.Message.message}</p>}
                        </div>

                        {/* Submit Button */}
                        <button disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default CreateBulkNotifications;
