import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import { BiPlus } from "react-icons/bi";
import { TablePagination } from "@mui/material";
import UserClasses from '../users/Users.module.css'

function Gifts() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [categories, setCategories] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchGifts = () => {
        setLoading(true);
        Services.getGifts("GET", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Gifts);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchGifts();
    }, []);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter(item =>
        item.GiftName.toLowerCase().includes(searchString.toLowerCase())
    );

    const slicedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const fetchCategories = () => {
        setLoading(true);
        Services.getCategories("POST", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setCategories(response.Categories);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <Layout Active={"Gifts"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gifts</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div>
                    <h1 style={{ color: '#FB3E7A' }}>GIFTS</h1>
                </div>
                <div className={CommonClasses["control-btns"]}>
                    <div className={UserClasses["search-container"]} style={{ width: '200px' }}>
                        <FaSearch className={UserClasses["search-icon"]} />
                        <input
                            type="text"
                            placeholder="Search Gifts By Name"
                            style={{ textIndent: '15px' }}
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            className={UserClasses["search-btn"]}
                        />
                    </div>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            navigate("/addgifts");
                        }}
                    >
                        <BiPlus size={20} /> Create New Gift
                    </button>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["GiftImage", "GiftName", "Material", "OfferPrice", "CreatedAt", "UpdateAt", "GiftStatus", "Actions"]}
                        />
                    ) : slicedData.length > 0 ? (
                        <div className={CommonClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>GiftId</th>
                                        <th>GiftImages</th>
                                        <th>GiftName</th>
                                        <th>OfferPrice</th>
                                        <th>CreatedAt</th>
                                        <th>GiftStatus</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slicedData.map((item) => (
                                        <tr
                                            key={item.GiftID}
                                            id={item.GiftID}
                                            name={item.GiftName}
                                        >
                                            <td>{item.GiftID}</td>
                                            <td>
                                                {item.GiftImages.length > 0 && (
                                                    <img
                                                        style={{ width: "10rem", height: "5rem", objectFit: "fill", margin: "0.5rem", borderRadius: '5px' }}
                                                        src={item.GiftImages[0]}
                                                        alt={`gift-${item.GiftID}-0`}
                                                        onClick={() => {
                                                            window.open(item.GiftImages[0], "_blank");
                                                        }}
                                                    />
                                                )}
                                            </td>
                                            <td>{item.GiftName}</td>
                                            <td>{item.OfferPrice}</td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.CreatedAt}
                                                </Moment>
                                            </td>
                                            <td>
                                                {item.GiftStatus === 1 ? (
                                                    <div className={CommonClasses["Status-active"]}>
                                                        <span>Active</span>
                                                    </div>
                                                ) : (
                                                    <div className={CommonClasses["Status-inactive"]}>
                                                        <span>Inactive</span>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <div className={CommonClasses["Action-btns"]}>
                                                    <button
                                                        onClick={() => navigate(`/updategift/${item.GiftID}`, { state: { gift: item } })}
                                                    >
                                                        <FaRegEdit />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <TablePagination
                                component="div"
                                count={filteredData.length}
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                rowsPerPageOptions={[5, 10, 25]}
                                className={CommonClasses["pagination"]}
                            />
                        </div>
                    ) : (
                        <div>
                            <p>No Gifts Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Gifts;
